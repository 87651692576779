import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container mt-5" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-12" }
const _hoisted_4 = { class: "table table-striped" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("table", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", null, "Фото"),
              _createElementVNode("th", null, "Назва категорії"),
              _createElementVNode("th", null, "Рік"),
              _createElementVNode("th", null, "Опис категорії"),
              _createElementVNode("th", null, "Дії")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.Photos, (photo) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: photo.key
              }, [
                _createElementVNode("td", null, [
                  _createElementVNode("img", {
                    src: photo.arrayImages[0],
                    style: {"max-height":"50px","max-width":"50px"}
                  }, null, 8, _hoisted_5)
                ]),
                _createElementVNode("td", null, _toDisplayString(photo.name), 1),
                _createElementVNode("td", null, _toDisplayString(photo.year), 1),
                _createElementVNode("td", null, _toDisplayString(photo.description), 1),
                _createElementVNode("td", null, [
                  _createVNode(_component_router_link, {
                    to: {name: 'edit_history', params: { id: photo.key }}
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createElementVNode("i", {
                        style: {"color":"#2a6aaa","margin-right":"10px"},
                        class: "fas fa-edit fa-2x"
                      }, null, -1)
                    ])),
                    _: 2
                  }, 1032, ["to"]),
                  _createElementVNode("button", {
                    onClick: _withModifiers(($event: any) => ($setup.deletePhoto(photo)), ["prevent"]),
                    style: {"margin":"10px"}
                  }, _cache[1] || (_cache[1] = [
                    _createElementVNode("i", {
                      style: {"color":"#ba2626"},
                      class: "fas fa-trash-alt fa-2x"
                    }, null, -1)
                  ]), 8, _hoisted_6)
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}